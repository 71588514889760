import request from "../api/request";

const baseUrl = "api"; // 设置环境接口的域名

// 用户登录
export function user_login(data) {
    return request({
        url: baseUrl + "/user/login",
        method: "POST",
        data
    });
}

export function user_register(data) {
    return request({
        url: baseUrl + "/user/register",
        method: "POST",
        data,
        is_email_token: true
    });
}

export function obtain_mail(data){
    return request({
        url: baseUrl + "/mail/get_code",
        method: "POST",
        data
    });
}

// 图片上传
export function image_upload(data){
    return request({
        url: baseUrl + "/image_upload",
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data
    });
}

// 获取球员列表
export function get_sportsman_list(data){
    return request({
        url: baseUrl + "/sportsman/list",
        method: "POST",
        is_token: true,
        data
    });
}
// 获取球员信息
export function get_sportsman(data){
    return request({
        url: baseUrl + "/sportsman/details",
        method: "POST",
        is_token: true,
        data
    });
}
// 添加球员
export function add_sportsman(data){
    return request({
        url: baseUrl + "/sportsman/add",
        method: "POST",
        is_token: true,
        data
    });
}

// 获取项字典
export function get_item_dict(data){
    return request({
        url: baseUrl + "/item/details",
        method: "POST",
        data
    });
}

export function get_my_info(){
    return request({
        url: baseUrl + "/my/info",
        method: "GET",
        is_token: true
    });
}

export function user_modify_password(data){
    return request({
        url: baseUrl + "/user/modify_password",
        method: "POST",
        is_token: true,
        data
    });
}

export function get_my_data_sportsman(){
    return request({
        url: baseUrl + "/my/data/sportsman",
        method: "GET",
        is_token: true,
    });
}

export function post_my_data_sportsman_edit(data){
    return request({
        url: baseUrl + "/my/data/sportsman/edit",
        method: "POST",
        is_token: true,
        data
    });
}

export function post_my_data_sportsman_audit(data){
    return request({
        url: baseUrl + "/my/data/sportsman/audit",
        method: "POST",
        is_token: true,
        data
    });
}

export function post_my_data_sportsman_delete(data){
    return request({
        url: baseUrl + "/my/data/sportsman/delete",
        method: "POST",
        is_token: true,
        data
    });
}

export function get_article_list(data){
    return request({
        url: baseUrl + "/article/list",
        method: "POST",
        is_token: true,
        data
    });
}

export function get_article_details(data){
    return request({
        url: baseUrl + "/article/details",
        method: "POST",
        is_token: true,
        data
    });
}

export function add_article(data){
    return request({
        url: baseUrl + "/article/add",
        method: "POST",
        is_token: true,
        data
    });
}

export function get_my_data_article(){
    return request({
        url: baseUrl + "/my/data/article",
        method: "GET",
        is_token: true
    });
}

export function post_my_data_article_edit(data){
    return request({
        url: baseUrl + "/my/data/article/edit",
        method: "POST",
        is_token: true,
        data
    });
}

export function post_my_data_article_delete(data){
    return request({
        url: baseUrl + "/my/data/article/delete",
        method: "POST",
        is_token: true,
        data
    });
}


export function post_my_data_article_audit(data){
    return request({
        url: baseUrl + "/my/data/article/audit",
        method: "POST",
        is_token: true,
        data
    });
}

export function get_home_data_sportsman(){
    return request({
        url: baseUrl + "/home/data/sportsman",
        method: "GET",
        is_token: true
    });
}

export function get_home_data_article(data){
    return request({
        url: baseUrl + "/home/data/article",
        method: "POST",
        is_token: true,
        data
    });
}

export function post_article_buy(data){
    return request({
        url: baseUrl + "/article/buy",
        method: "POST",
        is_token: true,
        data
    });
}

export function post_sportsman_buy(data){
    return request({
        url: baseUrl + "/sportsman/buy",
        method: "POST",
        is_token: true,
        data
    });
}