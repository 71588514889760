import axios from "axios";
import {error_hint} from "../tool/tools";
// 设置服务器时间
const service = axios.create({
    timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(
    // 设置请求头
    config => {
        // 设置来源头
        if(config["is_email_token"]){
            config.headers["X-Token"] = sessionStorage.getItem("X-Token");
        }
        // 是否携带Token
        if(config["is_token"]){
            config.headers["Token"] = sessionStorage.getItem("Token");
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

// 响应拦截器
service.interceptors.response.use(
    result => {
        // 接收后台参数状态
        const response = result.data;
        if (response.Code === 1){
            return response;
        }else {
            if(response.Code !== 30002){
                error_hint(response.Message);
            }
            return Promise.reject(response);
        }
    },
    error => {
        if(error.response) {
            // error.response有错误信息,是接口错误,不是取消请求
            // 获取错误码,弹出提示信息,reject()
            return Promise.reject(error.message);
        }else {
            // 直接reject
            return Promise.reject(error.message);
        }
    }
)
export default service;